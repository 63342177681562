/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

import CategoricalScheme from '../../CategoricalScheme';
import { ColorSchemeGroup } from '../../types';

// TODO: add the colors to the theme while working on SIP https://github.com/apache/superset/issues/20159
const schemes = [
  {
    id: 'myColor1',
    label: '我的主题1',
    group: ColorSchemeGroup.Featured,
    colors: [
      '#809DF5',
      '#89D366',
      '#FEC74B',
      '#FE7373',
      '#4FBD8A',
      '#74C3E2',
      '#FC8452',
      '#9A60B4',
      '#EF73A8',
      '#DF467D',
    ],
  },
  {
    id: 'myColor2',
    label: '我的主题2',
    group: ColorSchemeGroup.Featured,
    colors: [
      '#82A1FF',
      '#FD8C38',
      '#FFC23D',
      '#AFCD6A',

      '#F9776F',
      '#82C3FF',
      '#EEAF0C',
      '#6FC290',
      '#FE702D',
      '#9989FF',
    ],
  },
  {
    id: 'myColor3',
    label: '我的主题3',
    group: ColorSchemeGroup.Featured,
    colors: [
      '#EA587C',
      '#FCC331',
      '#2BD8B7',
      '#84ABFF',

      '#8E7BFF',
      '#F47F5F',
      '#FCC331',
      '#F9699C',
      '#1CD0F8',
      '#2DB1E3',
    ],
  },
  {
    id: 'myColor4',
    label: '我的主题4',
    group: ColorSchemeGroup.Featured,
    colors: [
      '#8B89E8',
      '#5BCACE',
      '#F4B02A',
      '#F1826A',

      '#2BD8B7',
      '#2DB1E3',
      '#1CD0F8',
      '#5A91FF',
      '#EB6192',
      '#73DFD9',
    ],
  },
  {
    id: 'myColor5',
    label: '我的主题5',
    group: ColorSchemeGroup.Featured,
    colors: [
      '#DF467D',
      '#809DF5',
      '#9A60B4',
      '#EF73A8',

      '#74C3E2',
      '#4FBD8A',
      '#FE7373',
      '#FEC74B',
      '#89D366',
      '#FC8452',
    ],
  },
].map(s => new CategoricalScheme(s));

export default schemes;
